:root {
  --background-image: url('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2Fcd%2F90%2F3e%2Fcd903eb3074de64cbea8cf8d9433145f.jpg&f=1&nofb=1&ipt=298077703f62fb5510fe851d2aa161bdc64c084e1d095633c9fb6a6762be404c&ipo=images');
  /* Color Palette */
  --primary-color: #F5D4D4;      /* Soft Blush Pink */
  --secondary-color: #C5D8D3;    /* Sage Green */
  --accent-color: #E75480;       /* Deep Pink */
  
  /* Neutral Colors */
  --neutral-white: #FFFFFF;
  --neutral-light: #F8F9FA;
  --neutral-dark: #343A40;

  /* Font Sizes */
  --font-size-small: 0.875rem;
  --font-size-normal: 1rem;
  --font-size-large: 1.25rem;
  --font-size-heading: 1.5rem;
  --font-heading: 'Playfair Display', serif;
  --font-subheading: 'Lora', serif;
  --font-body: 'Roboto', sans-serif;
  --font-accent: 'Merriweather', serif;
  --font-logo: 'Operetta52Bold', serif;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

body {
  background-image: var(--background-image);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

body {
  font-family: var(--font-body);
}

h1, h2 {
  font-family: var(--font-heading);
}

/* Overlay to ensure readability */
.app {
  position: relative;
  background-color: rgba(255, 255, 255, 0.521); /* Slightly transparent white */
  min-height: 100vh;
}

/* Adjust content containers to be more readable */
.container, 
.main-content, 
.footer {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  margin: 1rem 0;
  padding: 1rem;
}

/* Featured Section Styles */
/* Featured Section Styles */
.featured-section {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: var(--neutral-dark);
  width: 100vw; /* Full viewport width */
  position: relative;
  left: 50%; /* Center the section */
  right: 50%;
  margin-left: -50vw; /* Offset to make full width */
  margin-right: -50vw;
  padding: 3rem 0;
  text-align: center;
  height: calc(100vw * 304.4 / 1170); /* Maintain aspect ratio */
  max-height: 304.4px;
  min-height: 200px; /* Minimum height for smaller screens */
  display: flex;
  align-items: center;
  justify-content: center;
}

.featured-content {
  max-width: 1170px; /* Match your desired width */
  margin: 0 auto;
  padding: 0 15px;
}

.featured-section h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--accent-color);
}

.featured-section p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.featured-cta {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--accent-color);
  color: var(--neutral-white);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.featured-cta:hover {
  background-color: darken(#E75480, 10%);
}

/* Product Grid Enhancements */
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(277.650px, 1fr));
  gap: 1.5rem;
  width: 100%;
  justify-content: center;
  padding: 1rem 0;
}

.product-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 277.650px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.product-card-image {
  width: 100%;
  height: 400px; /* Reduced height */
  object-fit: cover;
  object-position: center;
}

.product-card:hover .product-card-image {
  transform: scale(1.05);
}

.product-card-content {
  padding: 0.75rem;
  text-align: center;
}

.product-card-content h3 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: var(--accent-color);
}

.product-card-content p {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.product-card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background-color: var(--secondary-color);
  gap: 0.5rem;
}

.product-card-actions a,
.product-card-actions button {
  flex: 1;
  background-color: var(--accent-color);
  color: var(--neutral-white);
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.8rem;
  text-align: center;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-card-actions a:hover,
.product-card-actions button:hover {
  background-color: darken(#E75480, 10%);
}

/* Add these styles to your existing App.css */

/* Footer Styles */
.footer {
  background-color: var(--secondary-color);
  color: var(--neutral-dark);
  padding: 2rem 0;
  margin-top: 2rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  margin-right: 1rem;
  min-width: 200px;
}

.footer-section h4 {
  margin-bottom: 1rem;
  color: var(--accent-color);
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: var(--neutral-dark);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: var(--accent-color);
}

.social-links {
  display: flex;
  gap: 1rem;
}

.copyright {
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0,0,0,0.1);
}

/* Add to App.css */
.category-section {
  margin-top: 2rem;
}

.category-grid {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
}

.category-card {
  flex: 1;
  padding: 1rem;
  text-align: center;
  background-color: var(--primary-color);
  color: var(--neutral-dark);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.category-card:hover {
  background-color: var(--secondary-color);
}

.about-section {
  background-color: var(--neutral-white);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  margin-top: 2rem;
  text-align: center;
}

.about-section p {
  max-width: 800px;
  margin: 1rem auto;
}

/* Header Styling */
.main-header {
  background-color: var(--neutral-white);
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.header-logo-center {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

.header-logo {
  flex-grow: 1;
  text-align: center;
}

.savitha-logo {
  font-size: 2.5rem;
  color: var(--accent-color);
  text-decoration: none;
  letter-spacing: 2px;
  text-align: center;
  font-family: var(--font-logo);
  font-weight: 700;
}

.header-nav {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
}

.header-nav a {
  text-decoration: none;
  color: var(--neutral-dark);
  font-weight: 600;
  text-transform: uppercase;
  transition: color 0.3s ease;
  position: relative;
}

.header-nav a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 50%;
  background-color: var(--accent-color);
  transition: all 0.3s ease;
}

.header-nav a:hover {
  color: var(--accent-color);
}

.header-nav a:hover::after {
  width: 100%;
  left: 0;
}



/* New Arrivals and Trending Sections */
.new-arrivals-section,
.trending-section {
  margin-top: 2rem;
}

.section-cta {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.section-heading h2 {
  margin: 0;
}

.section-heading a {
  color: var(--accent-color);
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.section-heading a:hover {
  color: darken(#E75480, 10%);
}

/* Category Showcase Styles */
.category-showcase {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.category-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 1200px;
}


.category-card {
  flex: 0 1 auto;
  padding: 10px 20px;
  background-color: var(--neutral-light);
  border-radius: 5px;
  transition: all 0.3s ease;
  margin: 0.5rem;
  cursor: pointer;
  min-width: 100px;
  text-align: center;
}

.category-card.active {
  background-color: var(--accent-color);
}


.category-card a {
  text-decoration: none;
  color: var(--neutral-dark);
  font-weight: 600;
  display: block;
  width: 100%;
}

.category-card.active a {
  color: var(--neutral-white);
}


.category-image-showcase {
  position: relative;
  width: 100%;
  max-width: 800px;
  overflow: hidden;
  margin-top: 1rem;
}

.category-image {
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.category-image.active {
  opacity: 1;
  position: relative;
}

.category-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 20px; /* Added border radius */
  max-height: 600px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Optional: add subtle shadow */
}



/* Updated Category and Product Section Styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  text-align: center; /* Centralize text content */
}

/* Featured Products Section */
.featured-products-section,
.category-section,
.new-arrivals-section,
.trending-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

/* Section Headings */
.container h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--accent-color);
  position: relative;
}

.container h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: var(--accent-color);
}

.product-card-image {
  border-radius: 15px; /* Border radius for product images */
  transition: transform 0.3s ease;
}

.product-card:hover .product-card-image {
  transform: scale(1.05);
}



/* Additional Centralization for Sections */
.section-cta {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  width: 100%;
}

/* Category Cards */
.category-card {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* Theme Toggle Button */
.theme-toggle-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: var(--accent-color);
  color: var(--neutral-white);
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.theme-toggle-btn:hover {
  transform: rotate(360deg);
  background-color: var(--secondary-color);
}


/* Side Menu Styles */
.menu-toggle {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--accent-color);
  background-color: var(--neutral-white);
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.menu-toggle:hover {
  transform: rotate(90deg);
}

.side-menu {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: var(--primary-color);
  transition: left 0.3s ease;
  z-index: 1500;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
}

.side-menu.open {
  left: 0;
}

.side-menu-content {
  padding: 2rem;
  position: relative;
  height: 100%;
}

.close-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: var(--accent-color);
}

.side-menu-nav {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;
}

.side-menu-item {
  text-decoration: none;
  color: var(--neutral-dark);
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.side-menu-item:hover {
  background-color: var(--secondary-color);
  color: var(--accent-color);
}

.side-menu-item svg {
  transition: transform 0.3s ease;
}

.side-menu-item:hover svg {
  transform: scale(1.1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
}



.product-card-image-container {
  position: relative;
  width: 100%;
  padding-top: 171.8%; /* Maintains aspect ratio */
  overflow: hidden;
}

.image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--neutral-light);
  color: var(--neutral-dark);
}

.featured-carousel {
  width: 100vw;
  position: relative;
  overflow: hidden;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  height: 650px; /* Fixed height */
}

.featured-images-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.featured-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  visibility: hidden;
  transition: 
    opacity 0.5s ease, 
    visibility 0.5s ease,
    transform 0.5s ease;
  transform: scale(1.1);
}

.featured-image.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  z-index: 2;
}

.featured-image.prev {
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1) translateX(-100%);
  z-index: 1;
}

.featured-image.next {
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1) translateX(100%);
  z-index: 1;
}

/* Responsive Adjustments */
@media (max-width: 1440px) {
  .container {
    max-width: 1200px;
    padding: 0 15px;
  }

  .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 1.25rem;
  }



  .product-card {
    max-width: 270px;
  }

  .product-card-image {
    height: 550px;
  }
}

@media (max-width: 1200px) {
  .featured-section {
    height: auto;
    padding: 2rem 0;
  }

  .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }

  .product-card {
    max-width: 250px;
  }
  
  .product-card-image {
    height: 350px;
  }

  .header-wrapper {
    max-width: 1000px;
  }

  .category-image-showcase {
    max-width: 700px;
  }
  .product-card-actions a,
  .product-card-actions button {
    font-size: 0.75rem;
    padding: 6px 10px;
  }

  .category-grid {
    max-width: 1000px;
  }

  .category-card {
    flex-basis: calc(33.333% - 1rem);
  }

  .featured-carousel {
    max-height: 500px;
  }
}

@media (max-width: 992px) {
  .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 0.75rem;
  }

  .product-card {
    max-width: 220px;
  }
  
  .product-card-image {
    height: 300px;
  }

  .featured-section h1 {
    font-size: 2rem;
  }

  .product-card-content h3 {
    font-size: 0.9rem;
  }

  .product-card-content p {
    font-size: 0.8rem;
  }

  .featured-section p {
    font-size: 1rem;
  }

  .header-wrapper {
    max-width: 900px;
  }

  .category-image-showcase {
    max-width: 600px;
  }

  .product-card-actions a,
  .product-card-actions button {
    font-size: 0.7rem;
    padding: 5px 8px;
  }

  .category-grid {
    max-width: 900px;
  }

  .category-card {
    flex-basis: calc(33.333% - 1rem);
    padding: 8px 15px;
    font-size: 0.9rem;
  }

  .category-image img {
    max-height: 500px;
  }

  .featured-carousel {
    max-height: 450px;
  }
}

@media (max-width: 768px) {
  .menu-toggle {
    top: 15px;
    left: 15px;
  }

  .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 0.5rem;
  }

  .product-card {
    max-width: 200px;
  }
  
  .product-card-image {
    height: 250px;
  }

  .category-grid {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .category-card {
    flex-basis: calc(50% - 1rem);
    margin: 0.5rem;
    padding: 8px 12px;
    font-size: 0.8rem;
  }

  .category-image img {
    max-height: 400px;
    border-radius: 15px;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 1rem;
    min-width: 100%;
  }

  .header-wrapper {
    flex-direction: column;
  }

  .header-nav {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .featured-section h1 {
    font-size: 1.75rem;
  }

  .product-card-content h3 {
    font-size: 0.8rem;
  }

  .product-card-content p {
    font-size: 0.7rem;
  }

  .product-card-actions {
    flex-direction: column;
    gap: 0.25rem;
  }

  .product-card-actions a,
  .product-card-actions button {
    width: 100%;
    font-size: 0.7rem;
    padding: 5px 8px;
  }

  .featured-section p {
    font-size: 0.9rem;
  }

  .savitha-logo {
    font-size: 2rem;
  }

  .header-wrapper {
    max-width: 100%;
    padding: 0 10px;
  }

  .category-image-showcase {
    max-width: 100%;
  }

  .featured-carousel {
    max-height: 350px;
  }
}

@media (max-width: 576px) {
  .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 0.5rem;
  }

  .product-card {
    max-width: 180px;
  }
  
  .product-card-image {
    height: 200px;
  }

  .featured-section h1 {
    font-size: 1.5rem;
  }

  .featured-section p {
    font-size: 0.8rem;
  }

  .savitha-logo {
    font-size: 1.75rem;
  }

  .product-card-content h3 {
    font-size: 0.7rem;
  }

  .product-card-content p {
    font-size: 0.6rem;
  }

  .product-card-actions a,
  .product-card-actions button {
    font-size: 0.6rem;
    padding: 4px 6px;
  }

  .category-grid {
    flex-direction: column;
    align-items: center;
  }

  .category-card {
    flex-basis: auto;
    width: 80%;
    margin: 0.5rem 0;
    padding: 10px 15px;
    font-size: 0.9rem;
  }

  .category-image img {
    max-height: 300px;
  }

  .featured-carousel {
    max-height: 250px;
  }
}

@media (max-width: 480px) {
  .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 0.25rem;
  }

  .product-card {
    max-width: 160px;
  }
  
  .product-card-image {
    height: 300px;
  }

  .featured-section h1 {
    font-size: 1.25rem;
  }

  .featured-section p {
    font-size: 0.75rem;
  }

  .savitha-logo {
    font-size: 1.5rem;
  }

  .menu-toggle {
    top: 10px;
    left: 10px;
    font-size: 1.25rem;
  }

  .category-card {
    width: 90%;
    padding: 8px 12px;
    font-size: 0.8rem;
  }

  .category-image img {
    max-height: 250px;
  }

  .featured-carousel {
    max-height: 200px;
  }
}

@media (max-width: 375px) {
  .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 0.25rem;
  }

  .product-card {
    max-width: 140px;
  }
  
  .product-card-image {
    height: 250px;
  }

  .featured-section h1 {
    font-size: 1rem;
  }

  .featured-section p {
    font-size: 0.7rem;
  }

  .savitha-logo {
    font-size: 1.25rem;
  }

  .featured-carousel {
    max-height: 150px;
  }
}

